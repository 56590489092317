import 'core-js';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import DefaultLayout from '../components/DefaultLayout';
import { ToastContainer } from 'react-toastify';
import '../styles/globals.css';
import '../styles/fonts.css';
import '../styles/nprogress.css';
import 'tailwindcss/tailwind.css';
import Router, { useRouter } from 'next/router';
import NProgress from 'nprogress';
import Head from "next/head";
import '../styles/customCalendarStyles.css';
import '../styles/nav.css';
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment-timezone";
import 'moment/locale/he.js';
import TagManager from "react-gtm-module"
import React, { useEffect } from "react";
import useScrollBlock from "../lib/useScrollBlock";
import Script from "next/script";
import { FpjsProvider } from '@fingerprintjs/fingerprintjs-pro-react';
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'

Sentry.init({
  dsn: "https://8fdc0e209d2f4a01bdcd0d6f269a2395@o1378590.ingest.sentry.io/6690595",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

moment.tz.setDefault("Asia/Jerusalem");
moment.locale('he');

Router.events.on('routeChangeStart', (url) => {
  NProgress.start();
})
Router.events.on('routeChangeComplete', () => {
  NProgress.done();
})

Router.events.on('routeChangeError', () => NProgress.done())

const theme = createTheme({
  direction: 'rtl'
})

function MyApp({ Component, pageProps }) {
  const [blockScroll, allowScroll] = useScrollBlock();
  const Layout = Component.Layout || DefaultLayout;
  const router = useRouter();

  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-54LC3ZB' });
  }, []);

  useEffect(() => {
    blockScroll();
    allowScroll();
  }, [router.asPath]);

  return (
    <>
      <Head>
        <title key='maintitle'>זמן טוב - ניהול תורים ולקוחות לעסק שלך</title>
        <meta name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        <meta charSet="utf-8" />
        <meta name="description" key='description'
          content="האפליקציה המתקדמת ביותר לניהול תורים למספרות, קוסמטיקאיות, מאמני כושר ועוד. הרשמו עכשיו!" />
        {/* <meta property="og:site_name" content="זמן טוב" />
        <meta property="og:type" content="website" /> */}
        <link rel="apple-touch-icon" sizes="180x180" key='icon1' href="/img/favicons/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" key='icon2' href="/img/favicons/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" key='icon3' href="/img/favicons/favicon-16x16.png" />
        <link rel="manifest" key='manifest' href="/img/favicons/site.webmanifest" />
        <link rel="mask-icon" href="/img/favicons/safari-pinned-tab.svg" color="#5bbad5" />
        <link rel="shortcut icon" key='icon' href="/img/favicons/favicon.ico" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="msapplication-config" content="/img/favicons/browserconfig.xml" />
        {/* <link key='canonical' rel="canonical" href={`https://zmantov.co.il/`} /> */}
      </Head>

      {/*<Script src='https://www.google.com/recaptcha/api.js?render=6LePhNokAAAAAF6FFYdx2NyEjBPYT_vwOVQMhREp&domain=zmantov.co.il'/>*/}

      <ToastContainer
        position="bottom-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={true}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
        theme='light'
      />
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='he'>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Layout>
            {/*<FpjsProvider*/}
            {/*  loadOptions={{*/}
            {/*    apiKey: "5I61tracBwazfBlZJMvA"*/}
            {/*  }}*/}
            {/*>*/}
            <Component {...pageProps} />
            {/*</FpjsProvider>*/}
          </Layout>
        </ThemeProvider>
      </LocalizationProvider>
    </>
  )
}

export default MyApp
